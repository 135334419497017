import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SparkleIcon from "../img/sparkle.svg"
import SparkleSmallIcon from "../img/sparkle-small.svg"
import SparkleMediumIcon from "../img/sparkle-medium.svg"
import Stats from "../widgets/Stats"
import Swiper from "swiper"
import $ from "jquery"
import ArrowBlack from "../img/arrow-black.svg"
import { addUtmTagsInUrls, setCookieFromUrl } from "../widgets/cookie"

export default function CaseStudies(props: any) {
  const [filters, setFilters] = React.useState([])
  const { allContentfulCaseStudies } = props.data
  const data = allContentfulCaseStudies.nodes.map((e) => ({
    summary: e.summary,
    featuredCaseStudies: e.featuredCaseStudies,
  }))[0]

  React.useEffect(() => {
    new Swiper(".caseStudiesSlider", {
      slidesPerView: 1.8,
      spaceBetween: 20,
      freeMode: true,

      breakpoints: {
        400: {
          slidesPerView: 2.5,
        },
        600: {
          slidesPerView: 3.5,
        },
        800: {
          slidesPerView: 3.5,
        },
        990: {
          slidesPerView: 1.8,
        },
        1200: {
          slidesPerView: 2.5,
        },
      },
    })

    const filterClass = "active"
    const arr = {
      arr1: [],
      arr2: [],
    }
    $(document).on("click", ".workFilter", function () {
      const parent = $(this).parents(".case-studies-filter")
      const dataFilter = $(this).attr("data-filter")
      const showAll = parent.find(".showAll")
      const workFilter = parent.find(".workFilter")
      const workItem = parent.find(".workItem")
      const arrName = parent.attr("data-array-name")
      if ($(this).hasClass("showAll")) {
        workFilter.removeClass(filterClass)
        showAll.addClass(filterClass)
        workItem.addClass(filterClass)
        arr[arrName] = []
      } else {
        showAll.removeClass(filterClass)
        if ($(this).hasClass(filterClass)) {
          $(this).removeClass(filterClass)
          removeActiveFilter(arr[arrName], dataFilter)
        } else {
          $(this).addClass(filterClass)
          arr[arrName].push(dataFilter)
        }

        if (arr[arrName].length > 0) {
          workItem.removeClass(filterClass)
          arr[arrName].forEach((element) => {
            $("." + element).addClass(filterClass)
            // $('.' + element).wrap('<hidden>');
          })
        } else {
          workFilter.removeClass(filterClass)
          showAll.addClass(filterClass)
          workItem.addClass(filterClass)
          arr[arrName] = []
          // $('hidden').unwrap();
        }
      }
    })

    function removeActiveFilter(arr, item) {
      const index = arr.indexOf(item)
      if (index > -1) {
        arr.splice(index, 1)
      }
    }
  }, [])
  React.useEffect(() => {
    setCookieFromUrl()
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  if (!props.data) return <div>empty</div>
  const p = {
    ...props,
    title: "Brands Looking for Influencers in India",
    description:
      "Learn how top brands in India run influencer marketing campaigns with OPA. A recognized brand in skincare, cosmetics, fashion, health, fitness, and more categories.",
  }
  return (
    <Layout {...p}>
      <div className="mainContainer">
        <section className="case-studies-banner">
          <div className="container">
            <br />
            <div className="case-studies-banner-left">
              <div>
                <h1>
                  <span className="text-highlight-long">Case Studies</span>
                </h1>
                <p>a glimpse into whats been working for other brands.</p>
                <div style={{ margin: "-20px 0px -20px 0" }}>
                  <div
                    className="case-studies-filter-left"
                    style={{ width: "100%" }}
                  >
                    <div className="csm-social-box">
                      <ul
                        style={{
                          display: "flex",
                          listStyle: "none",
                          width: "100%",
                        }}
                      >
                        <li
                          className={`workFilter showAll ${
                            filters.length === 0 ? "active" : ""
                          }`}
                          onClick={() => {
                            setFilters([])
                          }}
                        >
                          All
                        </li>
                        <li
                          className={`workFilter showAll ${
                            filters.includes("macro") ? "active" : ""
                          }`}
                          onClick={() => {
                            setFilters(
                              filters.includes("macro")
                                ? filters.filter((e) => e !== "macro")
                                : [...filters, "macro"]
                            )
                          }}
                        >
                          Macro Influencer Campaigns
                        </li>
                        <li
                          className={`workFilter showAll ${
                            filters.includes("micro") ? "active" : ""
                          }`}
                          onClick={() => {
                            setFilters(
                              filters.includes("micro")
                                ? filters.filter((e) => e !== "micro")
                                : [...filters, "micro"]
                            )
                          }}
                        >
                          Micro Influencer Campaigns
                        </li>
                      </ul>
                    </div>
                    {/* <div
                      className="csm-arrow rellax rellaxAnim"
                      data-rellax-speed="1"
                      data-rellax-percentage="0.5"
                    >
                      <img
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/hero-arrow_jiirdh.png"
                        alt=""
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              className="csm-sparkle-1-1 rellax rellaxAnim"
              data-rellax-speed="1"
              data-rellax-percentage="0.5"
            >
              <SparkleIcon />
            </div>
            <div
              className="case-studies-banner-right"
              style={{ width: "100%" }}
            >
              <div className="case-study-wrapper">
                {data.featuredCaseStudies
                  .filter((e) => {
                    if (filters.length === 0) return true
                    if (filters.includes("macro") && e?.tags?.includes("macro"))
                      return true
                    if (filters.includes("micro") && e?.tags?.includes("micro"))
                      return true
                    return false
                  })
                  .map((e) => (
                    <div className="case-study-item">
                      <Link
                        to={`/case-studies/${e.slug}/`}
                        style={{ padding: 10 }}
                      >
                        <div className="csImg">
                          <img
                            src={e?.previewContent?.previewImage}
                            alt="img"
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: e.title,
                          }}
                        />
                        {e?.previewContent?.previewDescription ? (
                          <p>{e?.previewContent?.previewDescription}</p>
                        ) : (
                          <></>
                        )}
                        Read more
                        <ArrowBlack />
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className="container">
        <section className="case-studies-filter" data-array-name="arr1">
          <div className="case-studies-filter-left">
            <img
              src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/instagram_jc1jx1.png"
              alt="Instagram"
            />
            <h3>
              <span className="text-highlight-long">Instagram</span>
            </h3>
            <div className="csm-social-box">
              <ul>
                <li className="workFilter showAll active" data-filter="all">
                  All
                </li>
                <li className="workFilter" data-filter="catA">
                  Fashion
                </li>
                <li className="workFilter" data-filter="catB">
                  Beauty
                </li>
                <li className="workFilter" data-filter="catC">
                  Health
                </li>
                <li className="workFilter" data-filter="catD">
                  Cosmetics
                </li>
                <li className="workFilter" data-filter="catE">
                  Skincare
                </li>
                <li className="workFilter" data-filter="catF">
                  Supplements
                </li>
                <li className="workFilter" data-filter="catG">
                  Fashion
                </li>
                <li className="workFilter" data-filter="catH">
                  Beauty
                </li>
                <li className="workFilter" data-filter="catI">
                  Health
                </li>
                <li className="workFilter" data-filter="catJ">
                  Cosmetics
                </li>
                <li className="workFilter" data-filter="catK">
                  Skincare
                </li>
              </ul>
            </div>
            <div
              className="csm-arrow rellax rellaxAnim"
              data-rellax-speed="1"
              data-rellax-percentage="0.5"
            >
              <img
                src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/hero-arrow_jiirdh.png"
                alt=""
              />
            </div>
          </div>
          <div className="case-studies-filter-right">
            <ul>
              <li className="workItem active catA">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-0_d2wcpz.png"
                    alt="NYKAA Brand Logo"
                  />
                </a>
              </li>
              <li className="workItem active catB">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-1_qouysj.png"
                    alt="Myob"
                  />
                </a>
              </li>
              <li className="workItem active catA">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-2_sl3d0e.png"
                    alt="Belimo"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-3_d0k7dh.png"
                    alt="Life Groups"
                  />
                </a>
              </li>
              <li className="workItem active catB">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-4_zo0dfv.png"
                    alt="Lilly"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-5_xf1f51.png"
                    alt="Citrus"
                  />
                </a>
              </li>
              <li className="workItem active catD">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-6_fncbtc.png"
                    alt="Trustly"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-7_ve3jal.png"
                    alt="Oldendorff"
                  />
                </a>
              </li>
              <li className="workItem active catE">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-8_j1kiqv.png"
                    alt="Grabyo"
                  />
                </a>
              </li>
              <li className="workItem active catF">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-9_nx4cyu.png"
                    alt="sugar"
                  />
                </a>
              </li>
              <li className="workItem active catG">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-10_rudfmk.png"
                    alt="purple"
                  />
                </a>
              </li>
              <li className="workItem active catH">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-1_qouysj.png"
                    alt="Myob"
                  />
                </a>
              </li>
              <li className="workItem active catI">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-2_sl3d0e.png"
                    alt="Belimo"
                  />
                </a>
              </li>
              <li className="workItem active catJ">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-3_d0k7dh.png"
                    alt="Life Groups"
                  />
                </a>
              </li>
              <li className="workItem active catK">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-4_zo0dfv.png"
                    alt="Lilly"
                  />
                </a>
              </li>
              <li className="workItem active catL">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-5_xf1f51.png"
                    alt="Citrus"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-6_fncbtc.png"
                    alt="Trustly"
                  />
                </a>
              </li>
              <li className="workItem active catL">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-7_ve3jal.png"
                    alt="Oldendorff"
                  />
                </a>
              </li>
              <li className="workItem active catK">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-8_j1kiqv.png"
                    alt="Grabyo"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-4_zo0dfv.png"
                    alt="Lilly"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-1_qouysj.png"
                    alt="Myob"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div
            className="csm-sparkle-1-2 rellax rellaxAnim"
            data-rellax-speed="1"
            data-rellax-percentage="0.5"
          >
            <SparkleIcon />
          </div>
          <div
            className="csm-sparkle-1-3 rellax rellaxAnim"
            data-rellax-speed="0.5"
            data-rellax-percentage="0.5"
          >
            <SparkleSmallIcon />
          </div>
          <div
            className="csm-sparkle-1-4 rellax rellaxAnim"
            data-rellax-speed="1"
            data-rellax-percentage="0.5"
          >
            <SparkleMediumIcon />
          </div>
        </section>
        <section className="case-studies-filter type2" data-array-name="arr2">
          <div className="case-studies-filter-left">
            <img
              src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/youtube_xtvmqp.png"
              alt="youtube video"
            />
            <h3>
              <span className="text-highlight-long">Youtube</span>
            </h3>
            <div className="csm-social-box">
              <ul>
                <li className="workFilter showAll active" data-filter="all">
                  All
                </li>
                <li className="workFilter" data-filter="catA">
                  Fashion
                </li>
                <li className="workFilter" data-filter="catB">
                  Beauty
                </li>
                <li className="workFilter" data-filter="catC">
                  Health
                </li>
                <li className="workFilter" data-filter="catD">
                  Cosmetics
                </li>
                <li className="workFilter" data-filter="catE">
                  Skincare
                </li>
                <li className="workFilter" data-filter="catF">
                  Supplements
                </li>
                <li className="workFilter" data-filter="catG">
                  Fashion
                </li>
                <li className="workFilter" data-filter="catH">
                  Beauty
                </li>
                <li className="workFilter" data-filter="catI">
                  Health
                </li>
                <li className="workFilter" data-filter="catJ">
                  Cosmetics
                </li>
                <li className="workFilter" data-filter="catK">
                  Skincare
                </li>
              </ul>
            </div>
            <div
              className="csm-arrow rellax rellaxAnim"
              data-rellax-speed="1"
              data-rellax-percentage="0.5"
              style={{ width: "auto" }}
            >
              <img
                src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/discover-graphic_ahjfsk.png"
                alt="arrow"
              />
            </div>
          </div>
          <div className="case-studies-filter-right">
            <ul className="niceScroll">
              <li className="workItem active catA">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-0_d2wcpz.png"
                    alt="NYKAA Brand Logo"
                  />
                </a>
              </li>
              <li className="workItem active catB">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-1_qouysj.png"
                    alt="Myob"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-2_sl3d0e.png"
                    alt="Belimo"
                  />
                </a>
              </li>
              <li className="workItem active catA">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-3_d0k7dh.png"
                    alt="Life Groups"
                  />
                </a>
              </li>
              <li className="workItem active catB">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-4_zo0dfv.png"
                    alt="Lilly"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-5_xf1f51.png"
                    alt="Citrus"
                  />
                </a>
              </li>
              <li className="workItem active catD">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-6_fncbtc.png"
                    alt="Trustly"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-7_ve3jal.png"
                    alt="Oldendorff"
                  />
                </a>
              </li>
              <li className="workItem active catE">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-8_j1kiqv.png"
                    alt="Grabyo"
                  />
                </a>
              </li>
              <li className="workItem active catF">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-9_nx4cyu.png"
                    alt="Sugar"
                  />
                </a>
              </li>
              <li className="workItem active catG">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-10_rudfmk.png"
                    alt="Purple"
                  />
                </a>
              </li>
              <li className="workItem active catH">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-1_qouysj.png"
                    alt="Myob"
                  />
                </a>
              </li>
              <li className="workItem active catI">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-2_sl3d0e.png"
                    alt="Belimo"
                  />
                </a>
              </li>
              <li className="workItem active catJ">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-3_d0k7dh.png"
                    alt="Life Groups"
                  />
                </a>
              </li>
              <li className="workItem active catK">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-4_zo0dfv.png"
                    alt="Lilly"
                  />
                </a>
              </li>
              <li className="workItem active catL">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-5_xf1f51.png"
                    alt="Citrus"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-6_fncbtc.png"
                    alt="Trustly"
                  />
                </a>
              </li>
              <li className="workItem active catL">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-7_ve3jal.png"
                    alt="Oldendorff"
                  />
                </a>
              </li>
              <li className="workItem active catK">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-8_j1kiqv.png"
                    alt="Grabyo"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-4_zo0dfv.png"
                    alt="Lilly"
                  />
                </a>
              </li>
              <li className="workItem active catC">
                <a href={`/case-studies/`}>
                  <img
                    src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client-1_qouysj.png"
                    alt="Myob"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div
            className="csm-sparkle-1-5 rellax rellaxAnim"
            data-rellax-speed="1"
            data-rellax-percentage="0.5"
          >
            <SparkleIcon />
          </div>
          <div
            className="csm-sparkle-1-6 rellax rellaxAnim"
            data-rellax-speed="0.5"
            data-rellax-percentage="0.5"
          >
            <SparkleIcon />
          </div>
        </section>
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulCaseStudies {
      nodes {
        featuredCaseStudies {
          title
          slug
          tags
          previewContent {
            previewImage
            previewDescription
          }
        }
        summary {
          id
          key
          value
          sub
        }
      }
    }
  }
`
